<template>
    <div>
        <el-container>
            <el-header height="100%">
              <vheadere class="hidden-sm-and-down"></vheadere>
              <ivheaderae class="hidden-md-and-up"></ivheaderae>
              <div class="top-img">
             <img class="hidden-sm-and-down" width="100%" src="../../assets/images/en/A/topimg.jpg" alt="">
             <img class="hidden-md-and-up" width="100%" src="../../assets/images/en/A/A1-topImg-m.png" alt="">
             <div class="top-img-top">
               <p>GREEN DEVELOPMENT</p>
             </div>
           </div>
            </el-header>
            <el-main class="adez">
                <div class="w adez-mid">
                  <div class="ec-top-foot">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item ><router-link to="/EN">>Home</router-link></el-breadcrumb-item>
                      <el-breadcrumb-item>Green Development</el-breadcrumb-item>
                    </el-breadcrumb>
                  </div>
                    <el-row type="flex" justify="center">
                        <el-col :sm="24" :md="24" :lg="24">
                            <div style="max-width: 500px; margin-bottom: 30px; margin-top: 0.3rem;">
                              <img width="100%" src="../../assets/images/en/A/A4i.jpg" alt="">
                            </div>
                            <div class="A-txt">
                                <div v-html="msg"></div>
                            </div>
                            <div v-for="item in midea" :key="item">
                                <!-- <video autoplay muted loop  width="100%" :src="item" alt="" poster="../../assets/images/common/loading.gif"></video> -->
                                <video class="hidden-sm-and-down" width="100%"  autoplay muted loop :src="item" poster="../../assets/images/cityplus.jpg"></video>
                                <video class="hidden-md-and-up" width="100%" controls preload muted loop :src="item" poster="../../assets/images/cityplus.jpg"></video>
                            </div>
                            <gotop></gotop>
                        </el-col>
                    </el-row>
                </div>
            </el-main>
            <el-footer>
              <vfootere class="hidden-sm-and-down"></vfootere>
              <ivfootere class="hidden-md-and-up"></ivfootere>
            </el-footer>
        </el-container>
    </div>
</template>

<script>
import Gotop from '../../components/gotop'
import { szcityd } from '../../api-en'

export default {
  data () {
    return {
      cityd: {},
      msg: {},
      midea: {}
    }
  },
  mounted () {
    szcityd().then(res => {
      // console.log(res.data)
      this.cityd = res.data
      // console.log(this.cityd)
      this.msg = res.data[0].ONE
      this.midea = res.data[0].TWO
      // console.log(this.midea)
      var arr = this.midea.split('</p>')
      // eslint-disable-next-line no-array-constructor
      var src = new Array()
      // console.log(arr)
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].indexOf('src') !== -1) {
          src.push(arr[i].match(/src="(\S*)"/)[1])
        }
      }
      // console.log(3)
      // console.log(src)
      this.midea = src
      // console.log(this.midea)
    })
  },
  components: {
    Gotop
  }
}
</script>

<style scoped>
.el-header {
    padding: 0;
}
.el-main {
    margin-top: 0;
    padding: 0;
    overflow: initial;
}
.el-main .w {
  padding: 15px;
}
.top-img {
    position: relative;
}
.top-img-top {
    position: absolute;
    top: 45%;
    left: 5%;
    color: #FFFFFF;
    text-align: left;
}
.top-img-top p {
    font-size: 0.6rem;
    font-weight: 600;
}
.top-img-top span {
    font-size: 0.45rem;
    font-weight: 300;
}
.top-img .top-img-top:after {
    content: '';
    position: absolute;
    left: 0;
    top: -16px;
    height: 1px;
    width: 40%;
    background: #FFF;
}
.adez .adez-mid {
  position: relative;
}
.adez .adez-mid .ec-top-foot {
    position: absolute;
    top: -48px;
    left: 18px;
    background: rgba(0, 182, 205,1);
    min-width: 6.5rem;
    height: 0.775rem;
    align-items: center;
}
</style>
<style>
.A-txt {
    margin-top: 0.6rem;
    font-size: 0.3rem;
}
.A-txt p {
    text-align: justify;
    margin-bottom: 20px;
}
.adez .adez-mid .ec-top-foot .el-breadcrumb {
    line-height: 0.78rem;
    padding-left: 10px;
}
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__inner a {
    color: #fff;
    font-weight: 400;
}
.el-breadcrumb__item .el-breadcrumb__separator {
    color: #fff;
}
.el-breadcrumb__inner a:hover {
    color: #fff600;
}
@media screen and (max-width:1100px) {
  .adez .adez-mid .ec-top-foot {
    display: none;
  }
}
</style>
